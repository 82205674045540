
import { defineComponent, ref, unref, toRefs, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElForm } from 'element-plus';

import { Rules } from 'async-validator';

import { getChannelCampaign } from '@/services/api';

import {
  useCreateChannelCampaign,
  useUpdateChannelCampaign
} from '@/composables/api';
import { PartialChannelCampaign } from '@/interfaces/ChannelCampaign';

/* eslint-disable @typescript-eslint/camelcase */
const DEFAULT_FORM_VALUES: PartialChannelCampaign & {} = {
  utm_medium: '',
  utm_campaign: '',
  utm_term: '',
  utm_content: '',
  utm_id: ''
};

const rules: Rules = {
  utm_medium: [
    {
      required: true
    }
  ],
  utm_campaign: [
    {
      required: true
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const channelId = useRoute().params.id as string;
    const campaignId = useRoute().params.cid as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref(DEFAULT_FORM_VALUES);
    const { isLoading: isCreatedLoading, mutate: create } =
      useCreateChannelCampaign();
    const { isLoading: isUpdatedLoading, mutate: update } =
      useUpdateChannelCampaign();

    onMounted(async() => {
      if (isEdit.value) {
        const res = await getChannelCampaign({ channelId, campaignId });
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        formValues.value = res.data;
      }
    });

    const submitForm = () => {
      const form = unref(formRef);

      form &&
        form.validate((valid: boolean) => {
          if (valid) {
            if (isEdit.value) {
              update(
                { channelId, campaignId, data: formValues.value },
                {
                  onSuccess() {
                    ElMessage.success({
                      message: 'success!',
                      type: 'success'
                    });

                    router.replace({
                      name: 'show-channel',
                      params: { id: channelId }
                    });
                  },
                  onError(error: any) {
                    ElMessage.error({
                      message: error.response?.data.message,
                      type: 'error'
                    });
                  }
                }
              );

              return;
            }

            create(
              { channelId, data: formValues.value },
              {
                onSuccess() {
                  ElMessage.success({
                    message: 'success!',
                    type: 'success'
                  });

                  router.replace({
                    name: 'list-channels'
                  });
                },
                onError(error: any) {
                  ElMessage.error({
                    message: error.response?.data.message,
                    type: 'error'
                  });
                }
              }
            );
          }
        });
    };

    return {
      formValues,
      formRef,
      rules,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm
    };
  }
});
